.label,
.centerNodeTitle,
.centerNodeCategory,
.centerNodeValue {
  fill: var(--body-color);
  font-family: var(--font-family);
}

.centerNodeCategory {
  font-weight: bold;
}

.rect {
  fill: var(--card-background);
}

.clickable {
  cursor: pointer;
}
