.menu {
  background: var(--menu-background);
  border: solid 1px var(--menu-border);
  border-radius: var(--border-radius);
  padding: var(--menu-spacing);
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  max-width: 250px;
}

.menuBtn {
  border-radius: var(--border-radius);
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  padding: var(--menu-item-spacing);
  cursor: pointer;
  will-change: background;
  transition: background 0.2s ease;
  color: var(--body-color);
  font-family: var(--font-family);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.active,
.menuBtn:hover {
  background: var(--menu-item-hover);
}

.menuBtn:focus {
  outline: none;
}