.sankey {
  overflow: visible;
  position: relative;
  z-index: 1;
}

.empty {
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
}

.empty p {
  margin: 0;
}

.empty h3 {
  margin-bottom: 5px;
}

.emptyBtn {
  cursor: pointer;
}

.emptyBtn:hover {
  text-decoration: underline;
}

* {
  /** General */
  --label-color: white;
  --body-color: #BFC8ED;
  --body-background: #131934;
  /* --card-background: #232944; */
  --card-background: #212946;
  --menu-background: #1C223D;
  --menu-border: #363C58;

  /** Galaxy */
  --galaxy-arc-fill: #111935;
  --galaxy-axis-label-color: #fff;
  --galaxy-axis-count-color: #8792C0;
  --galaxy-axis-orb-fill: #fff;
  --galaxy-axis-orb-stroke: #754FF6;
  --galaxy-confidence-fill: #BDBDBD;
  --galaxy-metric-label-color: #8792C0;
  --galaxy-metric-value-color: #CFCBE5;

  /** Tooltip variables ( reaviz ) */
  --tooltip-background: #1C223D;
  --tooltip-color: #fff;
  --tooltip-border-radius: 5px;
  --tooltip-spacing: 10px;
  --tooltip-border: solid 1px #363C58;

  color-scheme: dark;

  --font-family: Open Sans, system-ui, Avenir, Helvetica, Arial, sans-serif;
  --primary-color: #754FF6;
  --border-radius: 5px;
  --menu-spacing: 10px;
  --menu-item-spacing: 5px 10px;
  --menu-item-hover: var(--primary-color);  
}